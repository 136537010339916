const windowExists = () => typeof window !== 'undefined';

export const getRefreshToken = () =>
  windowExists() ? window.localStorage.refreshToken : undefined;

export const getAccessToken = () =>
  windowExists() ? window.localStorage.accessToken : undefined;

export const setRefreshToken = refreshToken => {
  if (windowExists()) window.localStorage.refreshToken = refreshToken;
};

export const setAccessToken = accessToken => {
  if (windowExists()) window.localStorage.accessToken = accessToken;
};

export const removeAuthTokens = () => {
  if (windowExists()) window.localStorage.removeItem('accessToken');
  if (windowExists()) window.localStorage.removeItem('refreshToken');
};

export const isAuthenticated = () => !!getAccessToken();

/**
 * Path to redirect after login/signup
 * @param {string} path
 */
export const setOnLoginRediret = path => {
  if (windowExists()) window.sessionStorage.onLoginPath = path;
};

export const getOnLoginRedirect = () =>
  windowExists() ? window.sessionStorage.onLoginPath : undefined;

export const setHasAcceptedCookies = () => {
  if (windowExists()) window.localStorage.hasAcceptedCookies = true;
};

export const getHasAcceptedCookies = () =>
  windowExists() ? window.localStorage.hasAcceptedCookies : false;

/**
 * flag to see if user got already annoyed by our reminder popup
 */
export const setSawReminderPopupFlag = () => {
  if (windowExists()) window.localStorage.sawReminderPopupFlag = true;
};

export const getSawReminderPopupFlag = () =>
  windowExists() ? window.localStorage.sawReminderPopupFlag : undefined;

/**
 * flag to get traced link origin
 */
export const setTracedLinkOrigin = origin => {
  if (windowExists()) window.localStorage.tracedLinkOrigin = origin;
};

export const getStoredTracedLinkOrigin = () =>
  windowExists() ? window.localStorage.tracedLinkOrigin : undefined;

/**
 * track course checkout
 */
export const setCourseCheckout = courseId => {
  if (windowExists()) window.sessionStorage.courseCheckout = courseId;
};

export const getCourseCheckout = () =>
  windowExists() ? window.sessionStorage.courseCheckout : undefined;

export const clearCourseCheckout = () => {
  if (windowExists()) window.sessionStorage.removeItem('courseCheckout');
};

/**
 * track wanted to buy
 */
export const setWantedToBuy = courseId => {
  if (windowExists()) window.sessionStorage.wantedToBuy = courseId;
};

export const getWantedToBuy = () =>
  windowExists() ? window.sessionStorage.wantedToBuy : undefined;

export const clearWantedToBuy = () => {
  if (windowExists()) window.sessionStorage.removeItem('wantedToBuy');
};
