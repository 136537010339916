const EVENTS = require('./events');
const USER_ROLES = require('./userRoles');
const CHANNELS = require('./channels');
const PERMISSIONS = require('./permissions');
const EXPERIMENTS = require('./experiments');
const ERROR_TYPES = require('./errorTypes');

module.exports = {
  EVENTS,
  USER_ROLES,
  CHANNELS,
  PERMISSIONS,
  EXPERIMENTS,
  ERROR_TYPES,
};
