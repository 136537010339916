import React, { useContext } from 'react';
import styled from 'styled-components';

import STYLES from '../../enums/styles';
import { CookieContext } from '../../contexts/cookie';
import Button from '../clickables/button';
import Stack from '../systems/stack';

const Popup = styled.div`
  margin: 2vh 5vw;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.background};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    margin: 2v 5vw;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CookieWarning = styled.div`
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    font-size: ${STYLES.fontSizes.xs};
  }
  p {
    line-height: 1.2;
  }
`;

const AcceptButton = styled.div`
  margin: 0;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    margin: 20px 0;
  }
`;

const CookiePopup = () => {
  const { showCookieBanner, handleUserAcceptedCookies } = useContext(
    CookieContext
  );

  if (!showCookieBanner) return null;
  return (
    <Popup>
      <CookieWarning>
        <Stack centered={false} gap="2px" collapseX>
          <b>Cookie-Warnung</b>
          <p>
            {
              'Wir setzen für die Bereitstellung unserer Dienste essenzielle Cookies ein. Sie können Cookies jederzeit in den Browsereinstellungen deaktivieren. Nähere Hinweise erhalten Sie in unserer '
            }
            <a href="/protection#cookies">Datenschutzerklärung.</a>
          </p>
        </Stack>
      </CookieWarning>
      <AcceptButton>
        <Button
          label="Akzeptieren"
          onClick={handleUserAcceptedCookies}
          primary
        />
      </AcceptButton>
    </Popup>
  );
};

export default CookiePopup;
