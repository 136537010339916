import API from './api';
import { postRequest, getRequest, putRequest } from './request';
import { removeAuthTokens } from '../../utilities/storage';
import { authenticate, GRANT_TYPES } from './oauth';

export const logUserIn = (email, password) => {
  const data = {
    grant_type: GRANT_TYPES.password,
    username: email,
    password,
  };
  return authenticate(data);
};

export const registerUser = ({ email, password, givenName, familyName }) =>
  postRequest(API.register, {
    user: {
      email,
      password,
      givenName,
      familyName,
    },
  }).then(() => logUserIn(email, password));

export const fetchUser = () => getRequest(API.me);

export const logUserOut = () => removeAuthTokens();

export const changePassword = password =>
  putRequest(API.password, { password });

export const applyResetPw = email => putRequest(API.token, { email });

export const checkoutCourse = id => postRequest(`${API.checkout}/${id}`, {});

export const putVideoProgress = (id, index, progress) =>
  putRequest(API.progress.replace('{id}', id).replace('{index}', index), {
    progress,
  });

export const putPermissions = permissions =>
  putRequest(API.permissions, { permissions });

export const resetVideoProgress = id =>
  putRequest(API.resetProgress.replace('{id}', id), {});
