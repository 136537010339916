import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import {
  getHasAcceptedCookies,
  setHasAcceptedCookies,
} from '../utilities/storage';

const tag = 'CookieContext';

// new context
const CookieContext = React.createContext({
  acceptedCookies: false,
  showCookieBanner: false,
  handleUserAcceptedCookies: () => {},
});

// corresponding provider component
function CookieProvider({ children }) {
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  // set to false so we are initally not rendering it (which looks ugly if it disappears again)
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    const localstoreageAccepted = getHasAcceptedCookies();
    if (acceptedCookies) {
      console.initGA();
    }
    if (localstoreageAccepted && !acceptedCookies) {
      setAcceptedCookies(true);
      setShowCookieBanner(false);
    } else if (!localstoreageAccepted && !acceptedCookies) {
      setShowCookieBanner(true);
    }
  }, [acceptedCookies]);

  const handleUserAcceptedCookies = useCallback(() => {
    console.tag(tag).debug('set user has accepted cookies');
    if (showCookieBanner) {
      setShowCookieBanner(false);
    }
    if (!getHasAcceptedCookies()) {
      setHasAcceptedCookies();
    }
    if (!acceptedCookies) {
      setAcceptedCookies(true);
    }
  }, [acceptedCookies, showCookieBanner]);

  const context = {
    acceptedCookies,
    showCookieBanner,
    handleUserAcceptedCookies,
  };

  return (
    <CookieContext.Provider value={context}>
      {acceptedCookies && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-408515162"
            type="text/javascript"
          />
        </Helmet>
      )}
      {children}
    </CookieContext.Provider>
  );
}

CookieProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { CookieContext, CookieProvider };
