import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

// new context
const ImagesContext = React.createContext({ data: {} });

// corresponding provider component
function GatsbyImagesProvider({ children }) {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const context = { data };

  return (
    <ImagesContext.Provider value={context}>{children}</ImagesContext.Provider>
  );
}

GatsbyImagesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { ImagesContext, GatsbyImagesProvider };
