module.exports = {
  default: 'default',
  fb_1: 'fb_1', // Facebook Ad 1
  fb_2: 'fb_2', // Facebook Ad 2
  fb_3: 'fb_3', // ...
  fb_4: 'fb_4',
  fb_5: 'fb_5',
  fb_6: 'fb_6',
  fb_7: 'fb_7',
  fb_8: 'fb_8',
  fb_9: 'fb_9',
  fb_10: 'fb_10',
  fb_11: 'fb_11',
  fb_12: 'fb_12',
  ga_1: 'ga_1', // Google Ad 1
  gt_1: 'gt_1', // Gesundheitsticket
  yt_1: 'yt_1', // Youtube Channel (e.g. Livestream description)
};
