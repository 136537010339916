import { ENV } from '../../enums';

const { apiUrl } = ENV;

const API = {
  token: `${apiUrl}/auth/token`,
  register: `${apiUrl}/users`,
  me: `${apiUrl}/users/me`,
  password: `${apiUrl}/users/me/password`,
  permissions: `${apiUrl}/users/me/permissions`,
  progress: `${apiUrl}/users/me/courses/{id}/videos/{index}`,
  resetProgress: `${apiUrl}/users/me/courses/{id}/videos`,
  checkout: `${apiUrl}/purchases/courses`,
  courses: `${apiUrl}/courses/`,
  events: `${apiUrl}/events/`,
};

export default Object.freeze(API);
