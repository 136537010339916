// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-courses-jsx": () => import("./../../../src/pages/courses.jsx" /* webpackChunkName: "component---src-pages-courses-jsx" */),
  "component---src-pages-imprint-mdx": () => import("./../../../src/pages/imprint.mdx" /* webpackChunkName: "component---src-pages-imprint-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-password-jsx": () => import("./../../../src/pages/password.jsx" /* webpackChunkName: "component---src-pages-password-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-protection-mdx": () => import("./../../../src/pages/protection.mdx" /* webpackChunkName: "component---src-pages-protection-mdx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */),
  "component---src-templates-course-jsx": () => import("./../../../src/templates/course.jsx" /* webpackChunkName: "component---src-templates-course-jsx" */),
  "component---src-templates-video-jsx": () => import("./../../../src/templates/video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */)
}

