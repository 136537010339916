import React from 'react';
import styled from 'styled-components';

import { STYLES } from '../../enums';
import CustomLink from '../clickables/customLink';

const FooterMenu = styled.footer`
  position: absolute;
  bottom: 0;
  margin-top: 10vh;
  margin-bottom: 5px;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
`;

const FooterItems = styled.ul`
  width: 100%;
  list-style-type: none;
  overflow: hidden;
  display: flex;
  padding: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  li {
    margin: 0 2vw;
    font-size: ${STYLES.fontSizes.xs};
    text-align: center;
  }
`;

const links = [
  {
    name: 'Impressum',
    link: '/imprint',
  },
  {
    name: 'AGBs',
    link: '/terms',
  },
  {
    name: 'Datenschutz',
    link: '/protection',
  },
];

const Footer = () => (
  <FooterMenu>
    <FooterItems>
      {links.map(link => (
        <li key={link.name}>
          <CustomLink link={link.link} title={link.name} isPage>
            {link.name}
          </CustomLink>
        </li>
      ))}
    </FooterItems>
  </FooterMenu>
);

export default Footer;
