import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { motion } from 'framer-motion';

import { ReactComponent as Menu } from '../../assets/svgs/menu.svg';

import { STYLES } from '../../enums';
import { NavigationContext } from '../../contexts/navigation';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import CustomButton from '../clickables/customButton';
import CustomLink from '../clickables/customLink';
import NavMenu from './navigation';
import Image from '../image/image';
import CookiesPopup from '../popups/cookies';
import useLatestCourseLink from '../../hooks/useLatestCourseLink';

const FixedHeader = styled(motion.header)`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  width: 100vw;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.box};
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: ${({ theme }) =>
    `${theme.border.width} ${theme.border.style} ${theme.border.color}`};
  box-shadow: 0 2px 8px
    ${({ theme }) => STYLES.hexToRgba(theme.colors.font, 0.15)};

  ul {
    padding-inline-start: 0;
  }

  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    ${({ isExpanded }) => (isExpanded ? 'bottom: 0;' : '')}
  }
`;

const ImageContainer = styled.div`
  width: 180px;
  margin: -24px 5vw 0 5vw;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 120px;
    margin: -18px 0 0 auto;
  }
  img {
    border-radius: 0;
  }
`;

const NavBar = styled.nav`
  margin: 3vh 5vw 1vh 5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NavMenuToggle = styled.div`
  justify-self: flex-start;
  svg {
    width: 28px;
  }
`;

const QuickNavRight = styled.ul`
  display: flex;
  margin-left: auto;
  list-style-type: none;
  li {
    margin-right: 2vw;
  }
`;

const QuickLinks = styled.ul`
  display: flex;
  list-style-type: none;
  li {
    margin-right: 2vw;
  }

  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    display: none;
  }
`;

// pages for quick access on desktop
const pages = [
  {
    name: 'Seminare',
    link: '/courses',
  },
  {
    name: 'Über mich',
    link: '/about',
  },
  {
    name: 'Kontakt',
    link: '/contact',
  },
];

const Header = ({ siteTitle }) => {
  const isLoggedIn = useIsLoggedIn();
  const theme = useContext(ThemeContext);
  const courseLink = useLatestCourseLink();
  const { toggleMenu, isOpen } = useContext(NavigationContext);

  const quickLinks = useMemo(
    () => (
      <QuickLinks>
        {pages.map(page => (
          <li key={page.name}>
            <CustomLink link={page.link} isPage>
              {page.name}
            </CustomLink>
          </li>
        ))}
      </QuickLinks>
    ),
    []
  );

  return (
    <FixedHeader
      isExpanded={isOpen}
      animate={{
        backgroundColor: theme.colors.background,
        borderBottom: theme.border.color,
      }}
      transition={{ duration: 0.85 }}
      initial={false}
    >
      <NavBar>
        <NavMenuToggle>
          <CustomButton
            onClick={toggleMenu}
            label="Toggle navigation menu"
            title={`${isOpen ? 'Close' : 'Open'} navigation menu`}
          >
            <Menu />
          </CustomButton>
        </NavMenuToggle>
        <ImageContainer>
          <CustomLink link="/" isPage>
            <Image src="Logo.png" alt="Psychologie für Sie" />
          </CustomLink>
        </ImageContainer>
        {quickLinks}
        <QuickNavRight>
          {!isLoggedIn && (
            <>
              <li className="hide-on-mobile">
                <CustomLink link="/signup" isPage>
                  Konto erstellen
                </CustomLink>
              </li>
              <li>
                <CustomLink link="/login" isPage>
                  Login
                </CustomLink>
              </li>
            </>
          )}
        </QuickNavRight>
      </NavBar>
      <CookiesPopup />
      {isOpen && <NavMenu />}
    </FixedHeader>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string,
};
Header.defaultProps = {
  siteTitle: ``,
};
export default Header;
