const ENV = {
  apiUrl: process.env.GATSBY_API_URL,
  apiSecret: process.env.GATSBY_API_SECRET,
  stripePublicKey: process.env.GATSBY_STRIPE_PUBLIC_KEY,
  logrocketProject: process.env.GATSBY_LOG_ROCKET_PROJECT,
  useLogrocket: process.env.GATSBY_LOG_ROCKET_ENABLED === 'true',
  logLevel: process.env.GATSBY_LOG_LEVEL,
  trackingEnabled: process.env.GATSBY_TRACKING_ENABLED === 'true',
  trackingExperiment: process.env.GATSBY_TRACKING_EXPERIMENT,
  mediaPath: process.env.GATSBY_MEDIA_PATH,
};

export default ENV;
