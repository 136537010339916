import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { STYLES } from '../../enums';
import { UserContext } from '../../contexts/user';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import Stack from '../systems/stack';
import CustomLink, { styles } from '../clickables/customLink';

const Menu = styled.nav`
  padding: 20px 5vw 5vh 5vw;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    margin: 5px 0 0 0;
    font-size: ${STYLES.fontSizes.xl};
  }
`;

const Section = styled.ul`
  border-bottom: 1px solid ${STYLES.colors.border};
  padding-bottom: 20px;
  li {
    line-height: 2.5;
    list-style-type: none;
    @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
      font-size: ${STYLES.fontSizes.xl};
    }
  }
`;

const Title = styled.li`
  list-style-type: none;
`;

const Action = styled.li`
  list-style-type: none;
  color: ${STYLES.colors.black}
  display: inline-flex;
  justify-items: center;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    font-size: ${STYLES.fontSizes.xl};
  }
  svg {
    margin-left: 10px;
    width: 28px;
  }
`;

const MotionedMenu = motion.custom(Menu);

const pages = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Seminare',
    link: '/courses',
  },
  {
    name: 'Über mich',
    link: '/about',
  },
  {
    name: 'Kontakt',
    link: '/contact',
  },
];

const NavMenu = () => {
  const isLoggedIn = useIsLoggedIn();
  const { handleLogout } = useContext(UserContext);

  const actions = useMemo(() => [], []);

  return (
    <MotionedMenu
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={{
        visible: { opacity: 1, transition: { duration: 1.5 } },
        hidden: { opacity: 0, transition: { duration: 0.6 } },
      }}
      role="dialog"
    >
      <Stack gap="20px" centered={false} collapseX>
        <h1>Navigation</h1>
        <Section>
          {pages.map(item =>
            item.title ? (
              <Title key={item.title}>
                <h2>{item.title}</h2>
              </Title>
            ) : (
              <li key={item.name}>
                <CustomLink link={item.link} isPage>
                  {item.name}
                </CustomLink>
              </li>
            )
          )}
        </Section>
        <h1>Profil</h1>
        <Section>
          <Title className={isLoggedIn ? 'hide' : ''}>
            <CustomLink link="/signup" isPage>
              Konto erstellen
            </CustomLink>
          </Title>
          <Title className={isLoggedIn ? 'hide' : ''}>
            <CustomLink link="/login" isPage>
              Login
            </CustomLink>
          </Title>
          <Title className={isLoggedIn ? '' : 'hide'}>
            <CustomLink link="/password" isPage>
              Passwort ändern
            </CustomLink>
          </Title>
          <Action className={isLoggedIn ? '' : 'hide'}>
            <CustomLink onClick={handleLogout} link="/" as={styles.asA} isPage>
              Ausloggen
            </CustomLink>
          </Action>
        </Section>
        {!!actions.length && (
          <>
            <h1>Barrierefreiheit</h1>
            <Section>{actions.map(action => action)}</Section>
          </>
        )}
      </Stack>
    </MotionedMenu>
  );
};

export default NavMenu;
