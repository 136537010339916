import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';

import { PERMISSIONS } from '../shared/enums';
import { LOADING_STATUS, ENV } from '../enums';
import { isAuthenticated } from '../utilities/storage';
import { fetchUser, logUserOut } from '../services/backend/user';
import { CookieContext } from './cookie';

// new context
const UserContext = React.createContext({
  user: undefined,
  setUser: () => {},
  status: LOADING_STATUS.isIdle,
  setStatus: () => {},
  wantsNewsletter: false,
  wantsReminder: false,
  handleLogout: () => {},
});

const tag = 'UserProvider';
// corresponding provider component
function UserProvider({ children }) {
  const [user, setUser] = useState();
  const [identified, setIdentified] = useState(false);
  const [status, setStatus] = useState(LOADING_STATUS.isIdle);
  const { handleUserAcceptedCookies } = useContext(CookieContext);

  const handleLogout = useCallback(() => {
    logUserOut();
    setUser(undefined);
    setStatus(LOADING_STATUS.isIdle);
  }, []);

  const wantsNewsletter = useMemo(
    () =>
      !!user && !!user.permissions.find(p => p === PERMISSIONS.sendNewsletter),
    [user]
  );
  const wantsReminder = useMemo(
    () =>
      !!user && !!user.permissions.find(p => p === PERMISSIONS.sendReminder),
    [user]
  );

  // once we are on the browser, run this code
  useEffect(() => {
    console.tag(tag).debug('user', user);

    if (isAuthenticated() && !user) {
      console.tag(tag).debug('we are autheticated');
      setStatus(LOADING_STATUS.isLoading);
      fetchUser()
        .then(fetchedUser => {
          console.tag(tag).debug('updating user', fetchedUser);
          setStatus(LOADING_STATUS.hasSucceeded);
          setUser(fetchedUser);
        })
        .catch(error => {
          console.tag(tag).error(error);
          setStatus(LOADING_STATUS.isIdle);
          setUser(undefined);
          logUserOut();
        });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      handleUserAcceptedCookies();
    }
    if (user && !identified && ENV.useLogrocket) {
      setIdentified(true);
      LogRocket.identify(user._id, {
        name: user.givenName,
        email: user._id,
        // Add your own custom user variables here, ie:
        role: user.role,
      });
    }
  }, [handleUserAcceptedCookies, identified, user]);

  const context = {
    user,
    setUser,
    status,
    setStatus,
    wantsNewsletter,
    wantsReminder,
    handleLogout,
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { UserContext, UserProvider };
