import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

// new context
const CoursesContext = React.createContext({ courses: [] });

// corresponding provider component
function CoursesProvider({ children }) {
  const {
    allCourses: { courses },
  } = useStaticQuery(graphql`
    query StaticCourses {
      allCourses {
        courses: nodes {
          description
          id
          price
          title
          imageUrl
          duration
          imageText
          previewUrl
          link
          manualUrl
        }
      }
    }
  `);

  const context = { courses };

  return (
    <CoursesContext.Provider value={context}>
      {children}
    </CoursesContext.Provider>
  );
}

CoursesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { CoursesContext, CoursesProvider };
