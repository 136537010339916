import { useContext, useMemo } from 'react';
import { UserContext } from '../contexts/user';
import useIsLoggedIn from './useIsLoggedIn';

const useLatestCourseLink = () => {
  const isLoggedIn = useIsLoggedIn();
  const { user } = useContext(UserContext);
  return useMemo(() => {
    if (!isLoggedIn || !user) {
      return undefined;
    }
    const { courses } = user;
    if (!courses || !courses.length) {
      return undefined;
    }
    console.log('useLatestCourseLink');
    console.log(courses);
    return undefined;
  }, [user, isLoggedIn]);
};

export default useLatestCourseLink;
