/* eslint-disable no-console */
import { EVENTS, EXPERIMENTS } from '../shared/enums';
import { ENV } from '../enums';
import { trackEvent } from '../services/backend/event';
import { getHasAcceptedCookies, isAuthenticated } from './storage';

const { logLevel } = ENV;

console.log('LOG_LEVEL', logLevel);

const logLevels = {
  error: 0,
  warn: 1,
  info: 2,
  http: 3,
  verbose: 4,
  debug: 5,
  silly: 6,
};

const shouldLog = ll => logLevels[logLevel] >= logLevels[ll];

/**
 * configures the logging utility and returns a unique logger
 * for individual label.
 * @param {string} logLabel Label to appear in logging
 * @returns {winston.Logger}
 */
console.tag = (logLabel = 'misc') => ({
  error: (message, ...params) =>
    shouldLog('error') &&
    console.error(`[${new Date()}] ${logLabel}: ${message}`, ...params),
  warn: (message, ...params) =>
    shouldLog('warn') &&
    console.warn(`[${new Date()}] ${logLabel}: ${message}`, ...params),
  info: (message, ...params) =>
    shouldLog('info') &&
    console.info(`[${new Date()}] ${logLabel}: ${message}`, ...params),
  http: (message, ...params) =>
    shouldLog('http') &&
    console.log(`[${new Date()}] ${logLabel}: ${message}`, ...params),
  verbose: (message, ...params) =>
    shouldLog('verbose') &&
    console.log(`[${new Date()}] ${logLabel}: ${message}`, ...params),
  debug: (message, ...params) =>
    shouldLog('debug') &&
    console.debug(`[${new Date()}] ${logLabel}: ${message}`, ...params),
  silly: (message, ...params) =>
    shouldLog('silly') &&
    console.log(`[${new Date()}] ${logLabel}: ${message}`, ...params),
});

/**
 * track user events for split testing
 * @param {string} event event name
 */
console.track = (event, experiment) => {
  const tag = 'track';
  const shouldTrack = ENV.trackingEnabled;
  if (!shouldTrack) {
    console.tag(tag).debug('we dont do this here');
    return;
  }
  const registeredEvent = Object.keys(EVENTS).find(
    key => EVENTS[key] === event
  );
  if (!registeredEvent) {
    console.tag(tag).error(`trying to track unkown event ${event}`);
    return;
  }
  if (experiment) {
    const registeredExp = Object.keys(EXPERIMENTS).find(
      key => EXPERIMENTS[key] === experiment
    );
    if (!registeredExp) {
      console.tag(tag).error(`trying to track unkown experiment ${experiment}`);
      return;
    }
  }
  if (!isAuthenticated()) {
    console.tag(tag).error('trying to track event without user logged in');
    return;
  }
  console
    .tag(tag)
    .debug(`sending event ${event} experiment ${experiment} to the backend`);
  trackEvent({
    name: event,
    experiment: experiment || ENV.trackingExperiment,
    branch: process.env.BRANCH,
  });
};

/**
 * google analytics gtag function
 */
console.gtag = (...args) => {
  console.tag('ga').debug('gtag called!');
  const hasAcceptedCookies = getHasAcceptedCookies();
  if (!hasAcceptedCookies || !window.dataLayer) {
    console.tag('ga').debug('no tracking!');
    return;
  }
  // eslint-disable-next-line no-undef
  gtag(...args);
};

/**
 * called once to set up ga
 */
console.initGA = () => {
  console.tag('ga').debug('cookies accepted, qa running');
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.onload = function() {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'AW-408515162', { anonymize_ip: true });
  };
  head.appendChild(script);
};
